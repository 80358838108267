<template>
  <div style="padding: 20px">
    <div style="margin: 10px 0 20px 0">
      <el-input
          :size="size"
          v-model="query.title"
          placeholder="活动名称"
          style="width: 260px;margin-right: 10px"
      ></el-input>
      <el-button style="margin:0 10px" type="primary" @click="selectActivityList(0)">查 询</el-button>
      <el-button type="primary" @click="addItem">新 增</el-button>
    </div>
    <el-table
        :data="activityList"
        border
        v-loading="listLoading"
        :row-style="{height:10+'px'}"
        :cell-style="{padding:2+'px'}"
        class="table"
        ref="multipleTable"
        height="630px"
        header-cell-class-name="table-header"
    >
      <el-table-column width="140" show-overflow-tooltip="true" prop="title" label="活动名称"/>
      <el-table-column show-overflow-tooltip="true" prop="address" label="活动地址"/>
      <el-table-column width="150" show-overflow-tooltip="true" prop="money" label="报名费用"/>
      <el-table-column width="140" align="center" label="缩略图">
        <template v-slot="scope">
          <el-image v-if="scope.row.picUrl !=null" class="code" :src="[scope.row.picUrl]"
          >
            <template #error>
              <div class="code"></div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column width="200" show-overflow-tooltip="true" prop="joinTime" label="参加时间"/>
      <el-table-column width="200" show-overflow-tooltip="true" prop="signEndTime" label="报名截止时间"/>
      <el-table-column width="150" show-overflow-tooltip="true" prop="coachName" label="创建人"/>
      <el-table-column width="200" show-overflow-tooltip="true" prop="addTime" label="创建日期"/>
      <el-table-column label="操作" fixed="right" width="100" align="center">
        <template #default="scope">
          <el-button
              size="small" style="padding: 0"
              type="text"
              @click="updateItem(scope.row)"
          >编辑
          </el-button>
          <el-button size="small" text style="padding: 0"
              type="danger"
              @click="deleteItem(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <add-activity @select-list="selectActivityList" ref="addActivity"></add-activity>
    <update-activity @select-list="selectActivityList" ref="updateActivity"></update-activity>
  </div>
</template>

<script>
import {selectActivityInfoPage,deleteActivity}from "@/api/activity.js";
import addActivity from "./AddActivity";
import updateActivity from "./UpdateActivity";
export default {
  name: "activityList",
  components:{
    addActivity,updateActivity
  },
  data() {
    return {
      size:'large',
      activityList:[],
      listLoading:false,
      pageTotal:0,
      query:{
        pageIndex:1,
        pageSize:10,
        title:"",
      }
    }
  },
  methods:{
    deleteItem(item){
      deleteActivity(item.id).then(val=>{
        if (val.code == 200){
          this.$message.success("删除成功！");
          this.selectActivityList();
        }else {
          this.$message.warning(val.message);
        }
      })
    },
    updateItem(item){
      this.$refs.updateActivity.openDialog(item);
    },
    addItem(){
      this.$refs.addActivity.openDialog();
    },
    handlePageChange(index) {
      this.selectActivityList(index);
    },
    selectActivityList(index){
      this.listLoading = true;
      if (index){
        this.query.pageIndex = index;
      }
      selectActivityInfoPage(this.query).then(val=>{
        this.listLoading = false;
        if (val.code == 200){
          this.activityList = val.data.records;
          this.pageTotal = val.data.total
        }
      })
    }
  },
  created() {
    this.selectActivityList();
  }
}
</script>

<style scoped>
.code {
  margin-top: 8px;
  width: 80px;
  height: 38px;
  border-radius: 6px;
  background-color: #efefef;
}
</style>