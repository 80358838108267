<template>
  <div>
    <!--    搜索框区域-->
    <div style="padding:40px 0 0 40px">
      <el-input v-model="query.coachName" placeholder="请输入教练姓名" class="inPut"></el-input>
      <!--      日期 筛选-->
      <el-date-picker
          style="margin-right:10px"
          v-model="v1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
      />
      <!--      部门 筛选-->
      <el-cascader
          v-model="query.deptId"
          style="width: 177px;margin-right: 10px;"

          placeholder="请选择部门"
          :props="{     value: 'id',
                        label: 'name',
                        children: 'children',
                        checkStrictly: true}"
          :options="deptList"/>
      <!-- checkStrictly是否 严格关联 上下级 勾选 -->
      <el-button type="primary" @click="search()">搜索</el-button>

      <el-button type="primary" v-permission="pList1"
                 @click="questionDialog = true" icon="el-icon-s-tools">管理
      </el-button>

      <el-popconfirm
          title="确定导出吗？"
          @confirm="exportBtn"
      >
        <template #reference>
          <el-button v-permission="pList2" class="background-animation">导出</el-button>
        </template>
      </el-popconfirm>
    </div>

    <!--    列表显示-->
    <div style="padding:20px 40px 0 40px">
      <el-table :data="questionList" border
                :cell-style="{ padding: 4 + 'px' }"
      >
        <el-table-column label="所属校区" show-overflow-tooltip="true" min-width="120">
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.campusName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="教练姓名">
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.coachName == null ? '暂无信息' : scope.row.remarkObject.coachName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-for="(e,i) in defaultData" :key="i" :label="e.formContent">
          <template #default="scope">
            {{ scope.row.applyContentObject[e.formKey] ? scope.row.applyContentObject[e.formKey] : 0 }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip="true" prop="yesterdayDate" label="沟通日期" min-width="140">
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.yesterdayDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="沟通图片">
          <template #default="scope">
            <div v-if="scope?.row?.remarkObject?.screenShot">
              <el-image
                  style="height:40px;width:40px"
                  :src="scope.row.remarkObject.screenShot[0]"
                  :zoom-rate="1.2"
                  :preview-src-list="scope.row.remarkObject.screenShot"
                  :initial-index="4"
                  fit="cover"
              >
                <template #error>
                  <div style="font-size:14px">
                    暂无图片
                  </div>
                </template>
              </el-image>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" show-overflow-tooltip>
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.remark }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="total"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!--    日报模版项管理 弹框-->
    <el-dialog v-model="questionDialog" title="日报模版项管理">
      <div style="margin-bottom:40px">
        <span>部门提报数据字段：</span>
        <el-select v-model="rules.checkedModuleIds" multiple placeholder="请添加">
          <el-option v-for="(item,index) in defaultData" :key="index" :label="item.formContent"
                     :value="item.id"></el-option>
        </el-select>
        <el-button style="margin-left: 20px;" type="primary" @click="adDialog = true ">添加</el-button>
        <el-button v-if="rules.checkedModuleIds.length>0" style="margin-left: 20px;" type="primary"
                   @click="editDialog = true ">编辑
        </el-button>

        <el-button style="margin-left: 20px;" type="primary" @click="copyCheckedModule">复制已勾选模版</el-button>
        <pre>日报提报模版内容: {{ copiedText }}</pre>
      </div>
      <div style="margin-bottom:40px">
        <span>数据提报限制时间：</span>
        <el-time-select
            v-model="rules.limitHourStart"
            :max-time="rules.limitHourEnd"
            class="mr-4"
            placeholder="开始限制日期"
            step="01:00"
            start="06:00"
            end="23:00"
        />
        <el-time-select
            v-model="rules.limitHourEnd"
            class="mr-4"
            placeholder="结束限制日期"
            step="01:00"
            start="06:00"
            end="23:00"
        />
      </div>
      <div style="margin-bottom:40px">
        <span>日报模版使用部门：</span>
        <el-cascader
            v-model="rules.checkDeptIds"
            placeholder="请选择部门"
            :props="{
                        multiple:true,
                        value: 'id',
                        label: 'name',
                        children: 'children'}"
            @change="handleUseDeptChange"
            :options="deptList"/>
        <!-- checkStrictly是否 严格关联 上下级 勾选-->
      </div>
      <!--      弹框底部按钮-->
      <template #footer>
        <el-button @click="questionDialog = false">取消</el-button>
        <el-button @click="confirmEdit" type="primary">确定</el-button>
      </template>
    </el-dialog>

    <!--      添加日报提报数据名称 弹框-->
    <el-dialog v-model="adDialog" title="添加提报数据名称">
      <div style="padding-left:20px">
        <span class="mr-4">数据名称:</span>
        <el-input style="width:270px" placeholder="请输入" v-model="moduleName"/>
      </div>

      <div style="padding-left:20px">
        <span class="mr-4">类型:</span>
        <el-select
            v-model="checkShowType"
            clearable
            placeholder="请选择表单格式,数字或名单"
            style="width: 240px"
        >
          <el-option
              v-for="item in showTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </div>
      <template #footer>
        <el-button @click="adDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </template>
    </el-dialog>

    <!--      修改日报提报数据名称 弹框-->
    <el-dialog v-model="editDialog" title="修改提报数据名称">
      <div style="padding-left:20px">
        <span class="mr-4">新数据名称:</span>
        <el-input style="width:270px" placeholder="请输入" v-model="editItem"/>
      </div>
      <template #footer>
        <el-button @click="editDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmItemEdit">确定</el-button>
      </template>
    </el-dialog>


    <!--    跳转到iframe网址-->
    <el-dialog v-model="aaa" height='666px'>
      <iframe src="https://www.yanhuotiyu.com/" class="ifr"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import {
  questionnaireList,
  exportExcel,
  applyModuleList,
  addApplyModule,
  addApplyDeptModule,
  modifyApplyModule
} from '@/api/questionnaire';
import {listXcxDept} from "@/api/dept";

export default {
  data() {
    return {
      pList1: ['set'],
      pList2: ['export'],
      editItem: '',
      editDialog: false,
      moduleName: '',
      aaa: false,
      adDialog: false,
      questionDialog: false,
      deptList: [],
      v1: [],
      rules: {//规则适用多个部门
        checkedModuleIds: [],//所选模版id集合
        limitHourStart: '',
        limitHourEnd: '',
        checkDeptIds: []//所选真实部门ID集合
      },
      query: {//搜索条件
        pageIndex: 1,
        pageSize: 10,
        coachName: '',
        endDate: '',
        startDate: '',
        deptId: null,
      },
      questionList: [],//数据集合
      total: null,//总数
      defaultData: [],//默认模版数据
      checkShowType: 'number',
      showTypeOptions: [
        {
          value: 'number',
          label: '数字类型',
        },
        {
          value: 'list',
          label: '名单类型',
        },
      ],
      copiedText: '' //复制到剪切板的内容
    }
  },
  created() {
    //加载数据
    this.getData()
    //获取部门
    this.getDept()
    //获取日报提报类型
    this.getApplyModuleList()
  },
  methods: {
    // 编辑日报项
    confirmItemEdit() {
      modifyApplyModule(this.rules.checkedModuleIds[0], this.editItem).then(res => {
        this.editDialog = false
        if (res.data.code == 200) {
          this.$message.success('修改成功！')
          this.getApplyModuleList()
        } else {
          this.$message.error('修改失败！')
        }
      })
    },
    //编辑 某部门的日报项
    confirmEdit() {
      if (this.rules.checkDeptIds && this.rules.checkDeptIds.length > 1) {
        this.rules.checkDeptIds.splice(0, this.rules.checkDeptIds.length - 1)
      }
      if (this.rules.limitHourStart) {
        let limitHourStartArr = this.rules.limitHourStart.split(':')
        this.limitHourStart = Number(limitHourStartArr[0])
      }
      if (this.rules.limitHourEnd) {
        let limitHourEndArr = this.rules.limitHourEnd.split(':')
        this.limitHourEnd = Number(limitHourEndArr[0])
      }
      addApplyDeptModule(this.rules).then(res => {
        this.questionDialog = false
        if (res.code == 200) {
          this.$message.success(res.data.message)
          this.getData()
        } else {
          this.$message.error('设置失败！')
        }
      })
    },
    //添加日报项
    confirmAdd() {
      if (!this.moduleName) {
        this.$message.error('请完整填写！')
      } else {
        this.adDialog = false
        addApplyModule(this.moduleName, this.checkShowType).then(res => {
          if (res.data.code == 200) {
            this.$message.success('添加成功')
            this.getApplyModuleList()
          }
        })
      }
    },
    //导出Excel
    exportBtn() {
      this.aaa = true
      exportExcel(this.query)
    },
    //日期格式化
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
      return time;
    },
    // 搜索加载数据
    getData() {
      if (this.query.deptId && this.query.deptId.length > 1) {
        this.query.deptId.splice(0, this.query.deptId.length - 1)
      }
      if (this.v1 != null && this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      } else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      //调用填写的内容
      questionnaireList(this.query).then(res => {
        if (res.code == 200) {
          this.questionList = res.data.records
          this.total = res.data.total
        } else {
          this.$message.error('请求数据失败！')
        }
      })
    },
    //分页点击搜索
    handlePageChange(e) {
      this.query.pageIndex = e
      this.getData()
    },
    //查询点击 搜索
    search() {
      this.query.pageIndex = 1
      this.getData()
    },
    //获取树状数据结构
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //加载多级部门初始数据
    getDept() {
      listXcxDept().then(res => {
        this.deptList = this.getTypeList(res.data);
      })
    },
    //加载默认的模版内容
    getApplyModuleList() {
      applyModuleList(-1).then(res => {
        if (res.code == 200) {
          this.defaultData = res.data.data.defaultData
        }
      })
    },
    //模版使用部门-点击勾选后
    handleUseDeptChange(selectedValues) {
      // 处理真正勾选的节点
      let trueSelectedNodes = this.getTrueSelectedNodes(selectedValues, this.deptList);
      console.log('真正勾选的管辖区域节点:', trueSelectedNodes);
      this.rules.checkDeptIds = trueSelectedNodes;
    },
    //获取真实选中的节点 返回真实选中数组
    getTrueSelectedNodes(selectedValues, options) {
      console.info(selectedValues)
      console.info(options)
      //查找前清空
      this.trueSelectOptions = [];
      //获取真实选中
      this.checkValidity(options, selectedValues)
      //返回真实选中节点
      return this.trueSelectOptions;
    },
    //获取真实选中节点 返回状态
    checkValidity(tree, validList) {
      //多维数组扁平化处理
      const flatValidList = validList.flat();
      //遍历树状数据
      return tree.map(node => {
        // console.info(node);
        //当前节点是否有效勾选--此处和自定义的value 有所区别,此处使用的value是id
        // let isValid = flatValidList.includes(node.value);
        let isValid = flatValidList.includes(node.id);

        if (node.children) {
          // 递归检查子节点
          const childValid = this.checkValidity(node.children, validList);
          // 判断所有子节点必须有效
          const allChildrenValid = childValid.every(valid => valid.isValid);
          // 判断若任意节点无效
          const anyChildInvalid = childValid.some(valid => !valid.isValid);

          // 如果所有子节点有效，当前节点有效；如果有任意子节点无效，当前节点无效
          isValid = isValid && allChildrenValid && !anyChildInvalid;
        }

        // console.log(`${node.label}: ${isValid ? '有效' : '无效'}`);
        //确定节点有效 才加入到真实选择
        if (isValid) {
          //和自定义树状数据 有所区别
          // this.trueSelectOptions.push(node.value)
          //查看返回的name以测试 是否是按照规则返回的
          // this.trueSelectOptions.push(node.name)
          this.trueSelectOptions.push(node.id)

        }
        // 返回节点的有效性
        return {isValid};
      });
    },
    //复制模版到剪切板
    copyCheckedModule() {
      // alert(this.rules.checkedModuleIds)
      //查找所勾选的模版内容并展示
      let selectedTexts = this.rules.checkedModuleIds.map(value => {
        //查找勾选模版id的text值
        const option = this.defaultData.find(opt => opt.id === value);

        //返回到模版之前 判定一下
        let tempContent=option ? option.formContent : '';
        if(option.showType==='number'){
          tempContent+="0；"//若为数字类型
        }else if(option.showType==='list'){
          tempContent+="张三、李四、王五、赵六；"//若为名单类型
        }
        return tempContent;
      }).join('\r\n');
      //改变下内容
      selectedTexts="\r\n张三的1月5日日报\r\n"+selectedTexts;
      // alert(selectedTexts);
      //如果支持navigator.clipboard
      if (navigator.clipboard) {
        //复制到剪切板1
        navigator.clipboard.writeText(selectedTexts).then(() => {
          this.copiedText = selectedTexts;
        }).catch(err => {
          console.error('Failed to copy: ', err);
        });
      } else {
        // 复制到剪切板2
        const textArea = document.createElement("textarea");
        textArea.value = selectedTexts;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          this.copiedText = selectedTexts;
          // alert('Copied to clipboard ' + (document.execCommand('copy') ? 'successful' : 'unsuccessful') + ': ' + selectedTexts);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      //前头增加一个换行
      // this.copiedText="\r\n张三的1月5日日报\r\n"+this.copiedText
    },
  }
}
</script>
<style scoped>
.inPut {
  margin: 0 10px;
  width: 260px
}

.background-animation {
  color: white;
  background: linear-gradient(-45deg, #ff00cc, #5454f8, #00ccff, #00ff33, #ffee00, #5454f8);
  background-size: 600% 600%;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mr-4 {
  margin-right: 40px;
}

.ifr {
  width: 100%;
  height: 600px;
}
</style>>

