import axios from 'axios';
import { ElMessage } from 'element-plus'
import nProgress from 'nprogress';
import 'nprogress/nprogress.css'
const service = axios.create({
    // 若瑶：22  曹：20   腾辉：43
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // baseURL: 'http://192.168.30.22:9099/api',
    // baseURL: 'http://192.168.30.102:9099/api',
    // baseURL: 'http://192.168.30.43:9099/api',
    // baseURL: 'https://minprogram.yanhuotiyu.com/api',
    baseURL: 'https://sportserver.yanhuotiyu.com/api',
    timeout: 1000000,
    contentType: "application/x-www-form-urlencoded"
});
service.interceptors.request.use(
    config => {
        // console.log(config)
        let token = localStorage.getItem('token')
        if (token && (config.url!='/sys/login' || config.url!='/sys/getAuthCode')) {
            config.headers['token'] = token;
        }
        nProgress.start()
        return config;
    },
    error => {
        console.log(error.response.data);
        return Promise.reject();
    }
);
service.interceptors.response.use(
    response => {
        nProgress.done()
        if (response.data.code===1030){
            ElMessage({
                showClose: true,
                message: '请勿频繁操作',
                type: 'warning',
            });
            return ;
        }
        if (response.data.code===5100){
            ElMessage({
                showClose: true,
                message: response.data.message,
                type: 'warning',
            });
            return ;
        }
        if (response.data.code===403){
            ElMessage({
                showClose: true,
                message: '没有权限',
                type: 'warning',
            });
            return ;
        }
        if (response.status == 200) {

            return  response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        // console.log(error.response.data);
        // return
        if (error.response.data.code == 401){
            localStorage.removeItem("token")
            window.location="/login"
        }
        return Promise.reject();
    }
);
export default service;

