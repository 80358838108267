<template>
  <el-dialog
      v-model="updateActivityDialog"
      title="添加商品"
      width="1200px"
  >
    <div style="margin-right: 50px;height: 480px;overflow: auto">
      <el-form :model="activity" :rules="activityRules" ref="activityRules" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="7">
            <el-form-item label="活动名称" prop="title">
              <el-input style="width: 250px" v-model="activity.title" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="活动地址" prop="address">
              <el-input style="width: 250px" v-model="activity.address" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="参加时间" prop="joinTime">
              <el-date-picker type="datetime" style="width:250px" value-format="YYYY/MM/DD HH:mm:ss" v-model="activity.joinTime"
                               placeholder="参加时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="报名截止时间" prop="signEndTime">
              <el-date-picker type="datetime" style="width:250px" value-format="YYYY/MM/DD HH:mm:ss" v-model="activity.signEndTime"
                               placeholder="报名截止时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item class="itemWih" label="报名费用" prop="money">
              <el-input style="width: 250px" v-model="activity.money" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="background-color: #F5F7FA;margin:10px 0 0 38px;padding: 10px 14px;border-radius: 10px">
              <div style="margin-bottom: 20px;font-weight: bold">活动图片</div>
              <div style="display:flex;">
                <el-image v-if="showImage"
                    style="width: 150px; height: 150px;margin-right: 10px"
                    :src="activity.picUrl"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.2"
                    :preview-src-list="[activity.picUrl]"
                    :initial-index="4"
                    fit="cover"
                />
                <el-upload
                    ref='upload'
                    class="upload"
                    accept="image/jpg,image/jpeg,image/png"
                    :http-request="uploadCardProduct"
                    :on-remove="(file, fileList) => {handleRemovePro(file, fileList)}"
                    :limit="1"
                    list-type="picture-card">
                  <el-button icon="Plus" text />
                </el-upload>
              </div>
              </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="color: red;margin: 20px 0 0 40px">编辑功能：仅可以更新“报名截止时间”字段 其他字段不可更改！！！</div>
    </div>
    <template #footer>

      <span class="dialog-footer">
        <el-button @click="updateActivityDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {uploadImage} from '@/api/helpManual'
import {updateActivity}from "@/api/activity.js";
export default {
  name: "AddProducts",
  data() {
    return {
      showImage:true,
      updateActivityDialog:false,
      activity:{
        address: "",
        joinTime: null,
        money: 0,
        picUrl: null,
        signEndTime: null,
        title: ""
      },
      activityRules: {
        title: [{
          required: true,
          message: '请输入活动标题！',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入活动地址！',
          trigger: 'blur'
        },],
        joinTime: [{
          required: true,
          message: '请选择活动开始时间！',
          trigger: 'change'
        }],
        signEndTime: [{
          required: true,
          message: '请选择活动结束时间！',
          trigger: 'change'
        }],
        money: [{required: true, message: '请输入金额！', trigger: 'blur'},],
      },
    }
  },
  methods:{
    openDialog(item) {
      this.activity = item;
      this.updateActivityDialog = true;
    },
    dateFormat(val) {
      let myDate = new Date(val);
      let year = myDate.getFullYear(); //年
      let month = myDate.getMonth() + 1; //月
      let day = myDate.getDate(); //日
      let hh = myDate.getHours(); //时
      let mm = myDate.getMinutes();
      let ss = myDate.getSeconds();
      const value = year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
      return value;
    },
    confirm(){
      this.$refs.activityRules.validate((valid)=>{
        if(valid){
          this.activity.joinTime = this.dateFormat(this.activity.joinTime);
          this.activity.signEndTime = this.dateFormat(this.activity.signEndTime);
          updateActivity(this.activity).then(val=>{
            if (val.code == 200){
              this.$emit('select-list');
              this.$message.success("添加成功！");
              this.updateActivityDialog = false;
            }else {
              this.$message.warning(val.message);
            }
          })
        }
      })
    },
    handleRemovePro(file){
      this.activity.picUrl = file.name;
    },
    async uploadCardProduct(file) {
      this.showImage = false;
      const fileName = file.file.name;
      const lastIndex = fileName.lastIndexOf(".");
      if (lastIndex !== -1) {
        const extension = fileName.substring(lastIndex + 1);
        let a = ['png', 'jpg', 'jpeg','PNG', 'JPG', 'JPEG'];
        if (a.includes(extension)) {
          await this.uploadImg(file,fileName)
        }else {
          this.$message.warning('图片格式有误！');
        }
      }
    },
    async uploadImg(file) {
      let url = null
      const data = new FormData()
      data.append(
          'multipartFile', file.file
      )
      data.append(
          'flag', 'card'
      )
      data.append(
          'fileName', file.file.name
      )
      await uploadImage(data).then(res => {
        if (res.code == 200) {
          this.activity.picUrl = res.data;
        } else {
          this.$message.warning('图片上传失败');
        }
      })
      return url
    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>