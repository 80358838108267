import request from '../utils/request';
/**
 *   查询活动列表
 */
export const selectActivityInfoPage = (param) => {
    return request({
        url: '/sys/activityInfo/selectActivityInfoPage',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 *   创建新的活动
 */
export const createdActivity = (item) => {
    return request({
        url: '/sys/activityInfo/add',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 *   更新新的活动
 */
export const updateActivity = (item) => {
    return request({
        url: '/sys/activityInfo/update',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 *   删除新的活动
 */
export const deleteActivity = (activityId) => {
    return request({
        url: '/sys/activityInfo/delete',
        method: 'get',
        params: { activityId }
    });
};