<template>
  <div style="padding:20px">
    <div style="display: flex;align-items: center;margin-bottom: 20px">
      <el-input
          v-model="query.name"
          placeholder="线索名称"
          style="width: 150px;margin-right: 10px"
      ></el-input>
      <el-input
          v-model="query.phone"
          placeholder="线索电话"
          style="width: 150px;margin-right: 10px"
      ></el-input>
      <el-input
          v-model="query.coachName"
          placeholder="创建老师"
          style="width: 150px;margin-right: 10px"
      ></el-input>
      <el-cascader
          ref="cascader"
          style="margin-right: 10px;width: 200px;"
          v-model="queryDept"
          placeholder="部门"
          :options="deptList"
          collapse-tags
          :collapse-tags-tooltip="true"
          clearable
          @change="handleChange"
          :props="myProps"/>
      <el-select v-model="query.state" placeholder="请选择状态">
        <el-option
            v-for="item in stateListOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <div style="margin-right: 10px">
        <el-date-picker
            style="width: 280px"
            v-model="queryCreateDate"
            type="daterange"
            range-separator="至"
            start-placeholder="创建时间"
            end-placeholder="创建时间"
        />
      </div>
      <el-button :loading="threadLoading" icon="Search" type="primary" @click="getPageList">搜索</el-button>
      <el-button icon="Download" type="primary" @click="exportCrmNewCustomers">导出</el-button>
    </div>
    <div>
      <el-table v-loading="threadLoading" :data="threadList" height="610px"
                :row-style="{ height: 10 + 'px' }"
                :cell-style="{ padding:'12px 0px'}">
        <el-table-column
            show-overflow-tooltip="true"
            prop="id"
            label="ID"
            width="90"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="name"
            label="姓名"
        />
        <el-table-column
            prop="phone"
            sortable
            label="电话"
        />
        <el-table-column
            prop="wxName"
            sortable
            label="微信号"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="schoolName"
            label="学校"
        />
        <el-table-column
            prop="studentGrade"
            sortable
            label="年级"
        />

        <el-table-column
            prop="deptName"
            sortable
            label="部门"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="stateName"
            sortable
            label="状态">
          <template #default="scope">
            <span v-if="scope.row.state == 0">跟进中</span>
            <span v-if="scope.row.state == 1">已成单</span>
            <span v-if="scope.row.state == 2">已抛出</span>
          </template>
        </el-table-column>

        <el-table-column
            show-overflow-tooltip="true"
            prop="createTime"
            label="创建时间"
            sortable
            width="160"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="coachName"
            sortable
            label="创建老师"
            width="120"
        />
        <el-table-column label="操作" width="120" fixed="right" align="center">
          <template #default="scope">
            <el-popover placement="right" :ref="`popover-${scope.$index}`" :width="700" trigger="click">
              <template #reference>
                <el-button style="font-size: 12px" type="text">跟进记录</el-button>
              </template>
              <el-table :data="scope.row.crmContactList">
                <el-table-column width="100" property="relationName" label="跟进老师"/>
                <el-table-column width="300" show-overflow-tooltip property="relationMatter" label="跟进内容"/>
                <el-table-column width="80" show-overflow-tooltip property="relationMatter" label="跟进图片">
                  <template #default="scope">
                    <el-image v-if="scope.row.recording"
                              style="width: 40px; height: 40px"
                              :src="JSON.parse(scope.row.recording)"
                              :preview-src-list="JSON.parse(scope.row.recording)"/>
                  </template>
                </el-table-column>
                <el-table-column width="180" property="relationTime" label="跟进时间"/>
              </el-table>
            </el-popover>
          </template>
        </el-table-column>


      </el-table>
      <div style="display: flex;justify-content: end;margin-top: 20px">
        <el-pagination
            v-model:current-page="query.pageIndex"
            v-model:page-size="query.pageSize"
            :page-sizes="[20, 50, 100, 150, 200, 250, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
            @size-change="getPageList"
            @current-change="getPageList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {statisticsByDivision, exportCrmNewCustomers} from "@/api/thread";
import {getListTree} from "@/api/dept";

export default {
  name: "NewThreadList",
  data() {
    return {
      threadLoading: false,
      threadList: [],//线索列表
      pageTotal: 0,//总条数
      deptList: [],//加载的多级部门数据
      queryCreateDate: [],//检索日期
      queryDept: [],//存储所有选中的部门节点
      selectedNode: null,// 存储选中的某个节点数据结构
      // deptOptions:[],//用于多级某一级别查找的部门选项
      myProps: {multiple: true, value: 'id', label: 'name', children: 'children'},//级联选择器的配置
      contactList: [],//联系人?
      stateListOptions:[
        {
          value: null,
          label: '全部'
        },
          {
        value: 0,
        label: '未成单'
      }, {
        value: 1,
        label: '已成单'
      }, {
        value: 2,
        label: '成单踢出'
      }],//状态选项
      query: {
        deptIds: [],//城市
        phone: "",
        name: "",
        coachName: "",
        endCreateTime: null,
        startCreateTime: null,
        state:null,
        pageIndex: 1,
        pageSize: 10,
      },

    }
  },
  methods: {
    //点击勾选后
    handleChange(selectedValues) {
      // 处理真正勾选的节点
      let trueSelectedNodes = this.getTrueSelectedNodes(selectedValues, this.deptList);
      console.log('真正勾选的节点:', trueSelectedNodes);
      this.queryDept = trueSelectedNodes;
    },
    //获取真实选中的节点 返回真实选中数组
    getTrueSelectedNodes(selectedValues, options) {
      console.info(selectedValues)
      console.info(options)
      //查找前清空
      this.trueSelectOptions = [];
      //获取真实选中
      this.checkValidity(options, selectedValues)
      //返回真实选中节点
      return this.trueSelectOptions;
    },
    //获取真实选中节点 返回状态
    checkValidity(tree, validList) {
      //多维数组扁平化处理
      const flatValidList = validList.flat();
      //遍历树状数据
      return tree.map(node => {
        // console.info(node);
        //当前节点是否有效勾选--此处和自定义的value 有所区别,此处使用的value是id
        // let isValid = flatValidList.includes(node.value);
        let isValid = flatValidList.includes(node.id);

        if (node.children) {
          // 递归检查子节点
          const childValid = this.checkValidity(node.children, validList);
          // 判断所有子节点必须有效
          const allChildrenValid = childValid.every(valid => valid.isValid);
          // 判断若任意节点无效
          const anyChildInvalid = childValid.some(valid => !valid.isValid);

          // 如果所有子节点有效，当前节点有效；如果有任意子节点无效，当前节点无效
          isValid = isValid && allChildrenValid && !anyChildInvalid;
        }

        // console.log(`${node.label}: ${isValid ? '有效' : '无效'}`);
        //确定节点有效 才加入到真实选择
        if (isValid) {
          //和自定义树状数据 有所区别
          // this.trueSelectOptions.push(node.value)
          //查看返回的name以测试 是否是按照规则返回的
          // this.trueSelectOptions.push(node.name)
          this.trueSelectOptions.push(node.id)

        }
        // 返回节点的有效性
        return {isValid};
      });
    },
    //导出文件
    exportCrmNewCustomers() {
      if (this.queryCreateDate == null || this.queryCreateDate.length < 1) {
        this.$message.error('导出文件，时间区间必选！')
        return
      } else {
        this.query.startCreateTime = this.dateFormat(this.queryCreateDate[0]);
        this.query.endCreateTime = this.dateFormat(this.queryCreateDate[1]);
      }
      if (this.queryDept?.length > 0) {
        console.log(this.queryDept);
        let list = [];
        this.queryDept.forEach((item) => {
          //已做过数组扁平处理,不再展开...item
          list.push(item)
        })
        this.query.deptIds = list;
      } else {
        this.query.deptIds = [];
      }
      exportCrmNewCustomers(this.query);
      this.$message.success('下载中，成功后将在右上角展示！');
    },
    //获取列表
    getPageList() {
      this.threadLoading = true;
      if (this.queryCreateDate != null && this.queryCreateDate.length > 0) {
        this.query.startCreateTime = this.dateFormat(this.queryCreateDate[0]);
        this.query.endCreateTime = this.dateFormat(this.queryCreateDate[1]);
      }
      if (this.queryDept?.length > 0) {

        console.log(this.queryDept);
        let list = [];
        this.queryDept.forEach((item) => {
          //已做过数组扁平处理,不再展开...item
          list.push(item)
        })
        this.query.deptIds = list;
      } else {
        this.query.deptIds = [];
      }
      statisticsByDivision(this.query).then(val => {
        this.threadLoading = false;
        if (val.code === 200) {
          this.threadList = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },
    //树状数据初始化
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //日期格式化
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created() {
    this.getPageList();
    //获取部门树状数据
    getListTree().then(res => {
      this.deptList = this.getTypeList(res.data);
    })
  },
}
</script>
<style type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}
</style>
<style scoped>

</style>
