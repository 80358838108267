<template>
  <el-dialog title='添加校区' v-model="addCampusDialog" width="900px">
    <div  custom-class="innerDialog" style="padding:10px 50px 0 0">
      <el-form :rules="campusRules" ref="campusRules" :model="addCampusForm" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="校区名称" prop="name">
              <el-input v-model="addCampusForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类型" prop="flag">
              <el-select @change="choiceFlag()" :size="size" placeholder="类型" v-model="addCampusForm.flag">
                <div v-if="sysStudentType==3">
                  <el-option label="中招体育" value="1"></el-option>
                  <el-option label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
                <div v-if="sysStudentType!=3">
                  <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                  <el-option v-else label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item  prop="principalId" label="负责人">
              <el-select
                  :disabled="addCampusForm.flag==null ? true:false"
                  v-model="addCampusForm.principalId"
                  filterable placeholder="请选择">
                <el-option
                    v-for="item in coachList"
                    :key="item.coachid"
                    :label="item.coachname"
                    :value="item.coachid"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="campusFlag" label="状态">
              <el-select v-model="addCampusForm.campusFlag" placeholder="状态">
                <el-option key="开课中" label="开课中" :value="'开课中'"></el-option>
                <el-option key="停课中" label="停课中" :value="'停课中'"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="经度" prop="longitude">
              <el-input placeholder="如：113.*******" v-model="addCampusForm.longitude"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维度" prop="latitude">
              <el-input placeholder="如：34.******" v-model="addCampusForm.latitude"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="排序" prop="sort">
              <el-input class="handle-select" v-model="addCampusForm.sort"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上午营业时间">
              <el-time-picker  
                  style="width: 150px;"
                  v-model="workDate.morning"
                  is-range
                  range-separator="~"
                  format='HH:mm'
                  value-format="HH:mm"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下午营业时间">
              <el-time-picker
                  style="width: 150px;"
                  v-model="workDate.afternoon"
                  is-range
                  range-separator="~"
                  format='HH:mm'
                  value-format="HH:mm"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div style="display:flex;">
          <el-form-item label="详情地址" prop="location">
            <el-input :rows="4" type="textarea" style="width: 420px" v-model="addCampusForm.location"
                      placeholder="如：**路与**路交叉路口向东50米"></el-input>
          </el-form-item>
          <div>
            <div>
              <el-form-item label="所属区域" prop="fatherId">
                <el-cascader
                    v-model="addCampusForm.fatherId"
                    :props="{
                   value: 'id',
                   label: 'name',
                   children: 'children',
                   checkStrictly: true}"
                    :options="treeList"/>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="所属部门" prop="deptId">
                <el-cascader
                    v-model="addCampusForm.deptId"
                    :props="{
                   value: 'id',
                   label: 'name',
                   children: 'children',
                  }"
                    :options="deptList"/>
              </el-form-item>
            </div>
          </div>

        </div>
        <el-button  onclick="window.open('https://lbs.qq.com/getPoint/')" style="margin-left: 50px">查询经纬度</el-button>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addCampusDialog = false">取消</el-button>
        <el-button type="primary" @click="addCampus()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {tree,addCampus} from "../../../api/area";
import {listCoachAll} from "@/api/coach";
import { listXcxDept } from "@/api/dept";
export default {
  name: "addCampus",
  props:{
    addDialog:String
  },
  data() {
    return {
      sysStudentType:localStorage.getItem('studentType'),
      workDate:{
        morning:null,
        afternoon:null,
      },
      coachList:[],
      deptList:[],
      treeList:[],
      addCampusDialog:false,
      addCampusForm:{
        name:"",
        principalId:null,
        flag:null,
        campusFlag:null,
        longitude:"",
        latitude:"",
        sort:null,
        location:"",
        fatherId:null,
        businessTime:""
      },
      campusRules: {
        name: [{
          required: true,
          message: '请输入校区名称！',
          trigger: 'change'
        },],
        principalId: [{
          required: true,
          message: '请选择校区负责人！',
          trigger: 'change'
        },],
        flag: [{
          required: true,
          message: '请选择类型！',
          trigger: 'change'
        }],
        campusFlag: [{
          required: true,
          message: '请选择校区当前状态！',
          trigger: 'change'
        }],
        longitude: [{
          required: true,
          message: '请输入经度！',
          trigger: 'change'
        }],
        latitude: [{
          required: true,
          message: '请输入维度！',
          trigger: 'change'
        }],
        sort: [{
          required: true,
          message: '请输入排序！',
          trigger: 'change'
        }],
        location: [{
          required: true,
          message: '请输入校区详细地址！',
          trigger: 'change'
        }],
        fatherId: [{
          required: true,
          message: '请选择所属区域！',
          trigger: 'change'
        }],
        deptId: [{
          required: true,
          message: '请选择所属部门！',
          trigger: 'change'
        }],
      },
    }
  },

  methods:{
    /**
     *选择类型查询教练
     */
    choiceFlag(){
      listCoachAll(this.addCampusForm.flag).then(val => {
        if (val.code === 200) {
          this.coachList = val.data
        } else {
          this.$message.error(`请求校区负责人失败！`);
        }
      })
    },
    /**
     * 添加校区
     */
    addCampus(){
      this.$refs['campusRules'].validate((valid) => {
        if (valid){
          this.addCampusForm.fatherId = this.addCampusForm.fatherId[this.addCampusForm.fatherId.length-1];
          let morning = this.workDate.morning;
          let afternoon = this.workDate.afternoon;
          if (morning==null && afternoon==null){
            this.$message.error("请选择营业时间！");
            return;
          }
          let morningDate = this.dateFormatC(morning[0])+' ~ '+this.dateFormatC(morning[1]);
          let afternoonDate = this.dateFormatC(afternoon[0])+' ~ '+this.dateFormatC(afternoon[1]);
          this.addCampusForm.businessTime = '上午 '+morningDate+' 下午'+afternoonDate;
          if (Array.isArray(this.addCampusForm.deptId)){
            this.addCampusForm.deptId = this.addCampusForm.deptId[this.addCampusForm.deptId.length - 1];
          }else {
            this.addCampusForm.deptId = null;
          }
          addCampus(this.addCampusForm).then(value => {
            this.addLogin = false;
            this.$emit("closeDialog");
            if(value.code == 200){
              this.$message.success("更新成功！");
            }else {
              this.$message.error("更新失败！");
            }
          })
          }
      })
    },
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    dateFormatC(val) {
      var myDate = new Date(val);
      var hh = myDate.getHours();            //时
      var mm = myDate.getMinutes();
      if (mm<=9){
        mm='0'+mm
      }
      return hh+':'+mm;
    }
  },
  created() {
    listXcxDept().then((res) => {
      this.deptList = this.getTypeList(res.data);
    });
    let data = new Date()
    this.workDate.morning = []
    this.workDate.afternoon = []
    this.workDate.morning.push(data.setHours(5, 0, 0, 0))
    this.workDate.morning.push(data.setHours(12, 0, 0, 0)) 
    this.workDate.afternoon.push(data.setHours(12, 0, 0, 0))
    this.workDate.afternoon.push(data.setHours(21, 0, 0, 0))
    tree(null).then(val => {
      if (val.code === 200) {
        this.treeList = this.getTypeList(val.data);
      } else {
        this.$message.error("获取区域数据失败！");
      }
    })
  },
  watch: {
    addDialog(news) {
      this.addCampusDialog=news;
    },
    addCampusDialog(){
      if (this.addCampusDialog==false){
        this.$emit("closeDialog");
      }
    }
  }
}
</script>

<style scoped>
.innerDialog{
  background-color: red;
}
</style>