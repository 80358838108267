<template>
  <div style="padding: 20px">

    <!--条件搜索-->
    <div class="handle-box">
      <el-button type="primary" class="mr10" @click="addModule = true">新增城市</el-button>
      <el-input v-model="query.region" clearable placeholder="城市" class="handle-select mr10"></el-input>
      <el-input v-model="query.chapterId" clearable placeholder="合同印章id" class="handle-select mr10"></el-input>
      <el-button type="primary" icon="Search" style="margin-left: 10px" @click="handleSearch">搜索
      </el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <!--table展示数据-->
    <el-table
        height="600px"
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        :row-style="{height:0+'px'}"
        :cell-style="{padding:0+'px'}"
    >
      <el-table-column
          width="180"
          show-overflow-tooltip="true"
          prop="chapterId"
          label="合同印章id"
      ></el-table-column>
      <el-table-column
          width="130"
          show-overflow-tooltip="true"
          prop="region"
          label="城市名称">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="cashierName"
          label="出纳">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="financialName"
          label="财务">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="contractTemplate"
          label="合同模板">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="receiptPhone"
          label="收据联系电话">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="tiedPayment"
          label="收据系服">
      </el-table-column>
      <el-table-column
          align="center"
          width="110"
          label="收据财务印章">
        <template v-slot="products">
          <el-image class="demo-image__preview" style="width: 45px; height: 45px"
                    :src="products.row.receiptChapterUrl"
                    :preview-src-list='stringToList(products.row.receiptChapterUrl)'>
            <template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline" style="margin-top: 20px"></i>
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          width="110"
          label="合同印章">
        <template v-slot="products">
          <el-image class="demo-image__preview" style="width: 45px; height: 45px"
                    :src="products.row.contractChapterImage"
                    :preview-src-list='stringToList(products.row.contractChapterImage)'>
            <template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline" style="margin-top: 20px"></i>
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          width="200"
          show-overflow-tooltip="true"
          prop="companyName"
          label="公司名称">
      </el-table-column>

      <el-table-column
          label="状态">
        <template #default="scope">
          <el-tooltip :content="scope.row.state==='开启'? '关闭后此城市将无法使用':'确定要开启吗'" placement="top">
            <el-switch
                @click="setState(scope.row)"
                v-model="scope.row.state"
                :active-value="'开启'"
                :inactive-value="'关闭'">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180" align="center">
        <template #default="scope">
          <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
          >编辑
          </el-button>
          <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog title="修改城市信息" v-model="editVisible" width="900px">
      <el-form :rules="rules" ref="editForm" :model="form" label-width="90px">
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="城市名称" prop="region">
              <el-input v-model="form.region"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="公司名称" prop="companyName">
              <el-input v-model="form.companyName" >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="出纳" >
              <el-input v-model="form.cashierName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="财务" >
              <el-input v-model="form.financialName" >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="合同模板" >
              <el-input v-model="form.contractTemplate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="state" label="状态">
              <el-input v-model="form.state"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="收据电话">
              <el-input v-model="form.receiptPhone" >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="收据系服" >
              <textarea v-model="form.tiedPayment" >
              </textarea>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="合同印章id" >
              <el-input v-model="form.chapterId" >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="receiptChapterUrl" label="收据印章">
          <div class="demo-image__preview" style="width: 500px">
            <el-input v-model="form.receiptChapterUrl"  style="width: 500px"></el-input>
            <el-image
                style="width: 100px; height: 100px"
                :src="form.receiptChapterUrl"
                :preview-src-list="form.receiptChapterUrl">
            </el-image>
          </div>
        </el-form-item>

        <div style="display: flex;height: 100px">
          <div style="margin:0 10px 0 51px">修改收据印章图片</div>
          <el-upload
              class="upload"
              :http-request="uploadHttp"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :limit="1"
              list-type="picture-card">
            <el-button icon="Plus" text />
          </el-upload>
        </div>

        <el-form-item prop="receiptChapterUrl" label="新合同印章">
          <div class="demo-image__preview"  style="width: 500px">
            <el-input v-model="form.contractChapterImage"  style="width: 500px"></el-input>
            <el-image
                style="width: 100px; height: 100px"
                :src="form.contractChapterImage"
                :preview-src-list="form.contractChapterImage">
            </el-image>
          </div>
        </el-form-item>

        <div style="display: flex;height: 100px">
          <div style="margin:0 10px 0 51px">修改新合同印章图片</div>
          <el-upload
              class="upload"
              :http-request="uploadHttpContact"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :limit="1"
              list-type="picture-card">
            <el-button icon="Plus" text />
          </el-upload>
        </div>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qx">取 消</el-button>
                    <el-button type="primary" @click="saveEdit('editForm')">确 定</el-button>
                </span>
      </template>
    </el-dialog>
    <!-- 新增弹出框 -->
    <el-dialog title="添加城市" v-model="addModule" width="900px">
      <el-form style="margin-right: 0px" :model="insertForm" :rules="rules" ref="insertForm" label-width="120px"
               class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="城市名称" prop="region">
              <el-input v-model="insertForm.region"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="公司名称" prop="companyName">
              <el-input v-model="insertForm.companyName" >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="出纳" prop="cashierName">
              <el-input v-model="insertForm.cashierName"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="财务" prop="financialName">
              <el-input v-model="insertForm.financialName" >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="合同模板" prop="contractTemplate">
              <el-input v-model="insertForm.contractTemplate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="state" label="状态">
              <el-select v-model="insertForm.state" style="width: 100px">
                <el-option key="开启" label="开启" :value="1"></el-option>
                <el-option key="关闭" label="关闭" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="收据电话" prop="receiptPhone">
              <el-input v-model="insertForm.receiptPhone" >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="收据系服" prop="tiedPayment">
              <textarea v-model="insertForm.tiedPayment" >
              </textarea>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="display: flex;height: 100px">
          <div style="margin:0 10px 0 51px">收据印章</div>
          <el-upload
              class="upload"
              :http-request="uploadHttp"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :limit="1"
              list-type="picture-card">
           <el-button icon="Plus" text />
          </el-upload>
        </div>

        <div style="display: flex;height: 100px">
          <div style="margin:0 10px 0 51px">合同印章</div>
          <el-upload
              class="upload"
              :http-request="uploadHttpContact"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :limit="1"
              list-type="picture-card">
            <el-button icon="Plus" text />
          </el-upload>
        </div>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="addModule=false">取 消</el-button>
					<el-button type="primary" @click="insertActivity('insertForm')">确 定</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import {getRegion, updateRegionState, insertRegion, updateRegion, deleteRegion} from "@/api/region";
import { uploadImage} from '@/api/helpManual'
export default {
  name: "Region",
  data() {
    return {
      tableData: [],//列表数据
      pageTotal: null,//分页总数
      addModule: false,//添加活动弹出层
      editVisible: false,//编辑 城市 弹出层
      insertForm: {},//新增城市的 表单
      form: {},//修改城市的 表单
      rules: {
        state: [{required: true, message: '请选择是否开启', trigger: 'blur'},],
        region: [{required: true, message: '请添加城市', trigger: 'blur'},],
        companyName: [{required: true, message: '请添加公司名称', trigger: 'blur'},],
        cashierName: [{required: true, message: '请添加出纳', trigger: 'blur'},],
        financialName: [{required: true, message: '请添加财务', trigger: 'blur'},],
        contractTemplate: [{required: true, message: '请添加合同模板', trigger: 'blur'},],
        receiptPhone: [{required: true, message: '请添加收据联系电话', trigger: 'blur'},],
        tiedPayment: [{required: true, message: '请添加收据系服', trigger: 'blur'},],
        chapterId: [{required: true, message: '请添加合同印章名称', trigger: 'blur'},],
      },//校验规则
      uploadConf: {
        region: 'oss-cn-beijing',
        accessKeyId: 'LTAI4G88H5Fc5mEjzXaQipfW',
        accessKeySecret: '0Z4k2fYL96OSVjyHM8BQTKoNZyvmDa',
        bucket: 'yanhuosports',
      },//oss的配置
      query: {
        pageIndex: 1,
        pageSize: 10,
        chapterId: '',
        region:''
      },//分页查询的入参
    }
  },
  methods: {
    getData() {
      getRegion(this.query).then(val => {
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
          this.region = val.data.region;
          this.chapterId = val.data.chapterId;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },
    // 删除操作
    handleDelete(row) {
      // 二次确认删除
      this.$confirm("确定要删除城市: " + row.region + " 吗? ", {
        type: "warning"
      }).then(() => {
        deleteRegion(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.getData()
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },

    //取消编辑
    qx() {
      this.editVisible = false;
      this.getData()
      this.$message.info(`您取消了修改`);
    },
    // 保存编辑
    saveEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateRegion(JSON.parse(JSON.stringify(this.form))).then(res => {
            this.editVisible = false;
            if (res.code == 200) {
              if (res.success) {
                this.$message.success(`修改城市: ${this.form.region} 成功`);
              }
            } else {
              this.$message.error(`修改城市: ${this.form.region} 失败`);
            }
            this.getData()
          })

        } else {
          return false;
        }
      })
    },
    /**
     * 获取时间
     */
    getCurrentTime() {
      //获取当前时间并打印
      let _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    },

    /**
     * 删除图片
     */
    handleRemove() {
      this.activityImg = '';
    },
    /**
     * 保存图片--收据章
     */
    uploadHttp({file}) {
      this.activityImg = file;
      const data = new FormData()
      let fileName = 'chapter/'
      data.append(
        'multipartFile', file
      )
      data.append(
        'fileName', fileName
      )
      data.append(
        'flag', 'chapter'
      )
      uploadImage(data).then(res=>{
        if(res.code==200){
          //将新增弹框中的 收据章地址 参数 换掉
          this.insertForm.receiptChapterUrl = res.data
          //将修改弹框中的 收据章地址 参数 换掉
          this.form.receiptChapterUrl=res.data
        }
      })
    },
    //更新 新合同章
    uploadHttpContact({file}) {
      this.activityImg = file;
      const data = new FormData()
      let fileName = 'chapter/'
      data.append(
          'multipartFile', file
      )
      data.append(
          'fileName', fileName
      )
      data.append(
          'flag', 'chapter'
      )
      uploadImage(data).then(res=>{
        if(res.code==200){
          //将新增弹框中的 收据章地址 参数 换掉
          this.insertForm.contractChapterImage = res.data
          //将修改弹框中的 收据章地址 参数 换掉
          this.form.contractChapterImage=res.data
        }
      })
    },
    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      const isJPG = file.name.split('.')[1] === 'jpg';
      const isPNG = file.name.split('.')[1] === 'png';
      const one = file.limit === 1;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (one>2) {
        this.$message.error('上传图片只能1张!');
      }
      return (isJPG || isPNG);
    },
    /**
     * 添加
     */
    async insertActivity(val) {
      // if (this.activityImg != '') {
      //   await this.insertOSS();
      // }
      this.$refs[val].validate((valid) => {
        if (valid) {
          insertRegion(this.insertForm).then(val => {
            this.addModule = false;
            if (val.code == 200) {
              this.$message.success('添加成功');
              this.getData();
            } else {
              this.$message.error('添加失败！');
            }
          })
        }
      })
    },
    /**
     * 改变状态
     */
    setState(val) {
      updateRegionState(val.id, val.state === "关闭" ? '关闭' : '开启').then(val => {
        if (val.code == 200) {
          this.getData();
          this.$message.success('更新成功');
        } else {
          this.$message.error('更新失败！');
        }
      })
    },
    /**
     * 切换页面
     */
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    // 触发搜索按钮
    handleSearch() {

      this.query.pageIndex = 1
      this.getData();
    },

    // 重置
    reset() {
      this.query.chapterId = ""
      this.query.region = ""
      this.query.pageIndex = 1
      this.getData()
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    stringToList(val) {
      let list = [];
      list.push(val);
      return list;

    }
  },

  /**
   * 加载页面
   */
  created() {
    this.getData();
  },

}

</script>

<style scoped>
.handle-box {
  display: flex;
  margin-bottom: 20px;
}

.handle-select {
  width: 160px;
}

.mr10 {
  margin-right: 10px;
}

.code {
  margin-top: 8px;
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #efefef;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}
</style>
