<template>
  <el-dialog v-model="updateDialog" draggable title="编辑" width="1400px" style="margin-top: 50px;">
    <div class="game-father">
      <div style="margin-bottom: 20px;font-weight: bold;font-size: 15px;margin-left: 20px">比赛信息</div>
      <el-form :model="PKGame" :rules="gameRules" ref="gameRules" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="5">
            <el-form-item label="比赛标题" prop="name">
              <el-input style="width: 200px;" v-model="PKGame.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="比赛时间" prop="startAndEndTime">
              <el-date-picker
                  v-model="PKGame.startAndEndTime"
                  type="datetimerange"
                  range-separator=" ~ "
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="机器人ID" prop="weChatRobotId">
              <el-input style="width: 200px;" v-model="PKGame.weChatRobotId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="规则说明图" prop="weChatRobotId">
              <el-upload v-if="PKGame.explain==null"
                         ref='upload'
                         class="upload"
                         :http-request="response => uploadGroupImage(response,index)"
                         :class="{hide:PKGame.explain!=null ? true:false}"
                         :limit="1"
                         list-type="picture-card">
                <el-button icon="Plus" text />
              </el-upload>
              <div v-if="PKGame.explain!=null" style="position: relative">
                <el-image class="picture" style="width: 86px;height: 40px;" :preview-src-list="[PKGame.explain]" :src="PKGame.explain" fit="cover"/>
                <div class="remove-picture" style="  top: -4px;left: 70px;" @click="removePicture(index)">×</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-if="updateDialog" style="display:flex;justify-content: space-between;">
      <div class="left-item">
        <el-tabs v-model="fatherTree.treeType" stretch class="demo-tabs">
          <el-tab-pane label="部门" :name="1">
            <el-tree style="height: 390px;overflow: auto;"
                     :data="fatherTree.deptTree" v-loading="fatherTree.regionLoading"
                     draggable :allow-drop="false" @node-drag-start="handleDragstart"
                     :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
          </el-tab-pane>
          <el-tab-pane label="老师" :name="2">
            <el-input
                v-model="fatherTree.coachName"
                @input="getCoach"
                style="width: 120px"
                size="small"
                placeholder="请输入姓名、电话"
            />
            <el-tree style="height: 360px;overflow: auto;margin: 10px 0 0 -10px"
                     :data="fatherTree.coachTree" v-loading="fatherTree.coachLoading"
                     draggable
                     :allow-drop="false"
                     @node-drag-start="handleDragstart"
                     :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}">
              <template #default="{ data }">
                <div class="tree-item">
                  <div style="margin: 10px 0;padding: 4px 0" text >{{data.id}} - {{ data.name }}</div>
                </div>
              </template>
            </el-tree>
          </el-tab-pane>
          <el-tab-pane label="账户" :name="3">
            <el-input
                v-model="fatherTree.regionName"
                @input="selectRegionAccount"
                style="width: 120px"
                size="small"
                placeholder="请输入名称"
            />
            <el-tree style="height: 360px;overflow: auto; margin: 10px 0 0 -10px"
                     :data="fatherTree.regionTree" v-loading="fatherTree.regionLoading"
                     draggable
                     :allow-drop="false"
                     @node-drag-start="handleDragstart"
                     :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"
            />
          </el-tab-pane>
          <el-tab-pane label="校区" :name="4">
            <el-select size="small" placeholder="校区类型" style="margin-bottom: 20px;width: 120px" @change="changeCampusType" v-model="fatherTree.campusType">
              <el-option label="全部" ></el-option>
              <el-option label="中招" value="1"></el-option>
              <el-option label="贝体" value="2"></el-option>
              <el-option label="高考" value="4"></el-option>
            </el-select>
            <el-tree style="height: 350px;overflow: auto;"
                     :data="fatherTree.campusTree" v-loading="fatherTree.campusLoading"
                     draggable
                     :allow-drop="false"
                     @node-drag-start="handleDragstart"
                     :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="right-item" style="display: flex;justify-content: space-around;flex-wrap: wrap">
        <div v-for="(item,index) in PKGame.group" :key="index">
          <div class="group-item" style="position: relative">
            <div style="display:flex;justify-content: space-between">
              <el-upload v-if="item.picture==null"
                         ref='upload'
                         class="upload"
                         :http-request="response => uploadGroupImage(response,index)"
                         :before-upload="beforeAvatarUpload"
                         :class="{hide:item.picture!=null ? true:false}"
                         :limit="1"
                         list-type="picture-card">
                <el-button icon="Plus" text />
              </el-upload>
              <div v-if="item.picture!=null" style="position: relative">
                <el-image class="picture" :preview-src-list="[item.picture]" :src="item.picture" fit="cover"/>
                <div class="remove-picture" @click="removePicture(index)">×</div>
              </div>
              <div>
                <div>组名：<input class="input" style="width: 128px;" v-model="item.name" size="mini" placeholder="请输入组名"/></div>
                <div>系数：<input class="input" style="width: 128px;" v-model="item.coefficient" size="mini" placeholder="请输入系数"/></div>
                <div>基础目标：<input class="input" style="width: 100px;" v-model="item.lowTarget" size="mini" placeholder="请输入"/></div>
                <div>冲刺目标：<input class="input" style="width: 100px;" v-model="item.tallTarget" size="mini" placeholder="请输入"/></div>
              </div>
            </div>
            <div class="el-tree-father" v-on:mouseover="handleMouseover(index)">
              <el-tree
                  v-if="item.groupMember.length>0"
                  :data="item.groupMember"
                  draggable
                  :allow-drop="true"
                  :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"
              >
                <template #default="{ node,data }">
                  <div class="tree-item">
                    <el-button style="margin: 10px 0;padding: 10px 0" text >{{ node.label }}</el-button>
                    <el-button style="margin: 10px 0;padding: 10px 0" text type="danger" @click="remove(node, data,index)" icon="Delete" circle />
                  </div>
                </template>
              </el-tree>
              <el-empty v-else imageSize="80px" description="将左侧的树结构拖拽到此处！"/>
            </div>
            <el-popconfirm
                :width="200"
                @confirm="deletePKGroup(item.id,index)"
                title="确定删除该队伍吗？"
                confirm-button-text="确定"
                cancel-button-text="取消">
              <template #reference>
                <el-button style="position: absolute;top: 4px;right: 4px;font-size: 18px" text >×</el-button>
              </template>
            </el-popconfirm>

          </div>
        </div>
        <div class="group-item"
             @click="addGroupItem"
             style="line-height: 300px;text-align: center;font-size: 30px;">+
        </div>
        <div class="placeholder"></div>
        <div class="placeholder"></div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="groupingDialog = false">取消</el-button>
        <el-button type="primary" @click="confirm()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

import {listAreaTreeAddCoach} from "@/api/area.js";
import { uploadImage} from '@/api/helpManual'
import {selectRegionAccount} from "../../../api/region";
import {pageList} from "@/api/coach";
import {listXcxDept} from "@/api/dept";
import {updatePKGame,selectMemberByGroup,deletePKGroup} from "@/api/pkGame/pkGame";
export default {
  name: "UpdateGame",
  data() {
    return {
      updateDialog:false,
      fatherTree:{
        treeType:1,
        deptTree:[],

        coachName:"",
        coachTree:[],
        coachLoading:false,

        regionName:"",
        regionTree:[],
        regionLoading:false,

        campusTree:[],
        campusType: null,
        campusLoading:false,
      },
      dragData:{},
      dragState:false,
      PKGame:{},
      gameRules: {
        name: [{
          required: true,
          message: '请输入比赛标题！',
          trigger: 'blur'
        },],
        startAndEndTime: [{
          required: true,
          message: '请选择比赛时间！',
          trigger: 'change'
        },],
        weChatRobotId: [{
          required: true,
          message: '请输入机器人ID！',
          trigger: 'blur'
        },],
      },
    }
  },
  methods: {
    confirm(){
      this.$refs.gameRules.validate((valid)=>{
        if(!valid){ return }
        this.PKGame.startTime = this.dateFormat(this.PKGame.startAndEndTime[0]);
        this.PKGame.endTime = this.dateFormat(this.PKGame.startAndEndTime[1]);
        let group =  this.PKGame.group;
        if (group.length<1){this.$message.error("分组太少，请点击 + 添加组！"); return }
        for(let index in group){
          //组表单验证
          if (!this.verification(group[index],Number(index)+1)){ return false }
          group[index].groupMemberList = [];
          group.groupMember = [];
          this.disposeGroupTree(group[index].groupMember,null,index);
        }
        this.updatePKGame();
      })
    },
    uploadGroupImage(file,index){
      const data = new FormData()
      data.append('multipartFile',file.file)
      data.append('flag','group')
      data.append('fileName',file.file.name)
      uploadImage(data).then(res=>{
        if (index == null){
          this.PKGame.explain = res.data;
        }else {
          this.PKGame.group[index].picture = res.data;
        }
        console.log(this.PKGame)
        console.log(this.PKGame)
      })
    },
    deletePKGroup(id,index){
      deletePKGroup(id).then(val => {
        if (val.code == 200) {
          this.$emit("selectTable");
          this.$message.success('删除成功！');
          this.PKGame.group.splice(index, 1);
        }else {
          this.$message.error("删除失败！");
        }
      })

    },
    removePicture(index){
      if (index == null){
        this.PKGame.explain = null;
      }else {
        this.PKGame.group[index].picture = null;
      }
    },
    /**
     * 移除组下面的树结构某个目录
     */
    remove(node,data,groupIndex) {
      const parent = node.parent;
      this.PKGame.group[groupIndex].isUpdateMember = 1;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
      for (const item of this.PKGame.group) {
        if (item.groupMember == null || item.groupMember.length==0){
          item.type = null;
        }
      }
    },

    openDialog(item){
      this.PKGame = JSON.parse(JSON.stringify(item));
      this.PKGame.startAndEndTime = [item.startTime,item.endTime]
      selectMemberByGroup(this.PKGame.group).then(val => {
        if (val.code == 200){
          for (const item of val.data) {
            item.type = item.groupMember[0].memberType;
            item.groupMember = this.disposeTree(item.groupMember)
          }
          this.PKGame.group = val.data;
          this.updateDialog = true;
        }else {
          this.$message.error("队成员获取失败！");
        }
      })
    },
    /**
     * 开始拖拽事件
     */
    handleDragstart (node) {
      this.dragState = true;
      this.dragData = node.data;
    },
    /**
     * 拖拽结束后会触发，鼠标移入事件，然后进行数据移入
     */
    handleMouseover(val) {
      if (this.dragState == true){
        this.PKGame.group[val].isUpdateMember = 1;
        if (this.PKGame.group[val].type == null){
          this.PKGame.group[val].type = this.fatherTree.treeType;
        }
        this.dragState = false;
        if (this.fatherTree.treeType == this.PKGame.group[val].type){
          this.PKGame.group[val].groupMember.push(JSON.parse(JSON.stringify(this.dragData)));
        }else {
          this.$message.error("树结构类型不符！");
        }
      }
    },

    /**
     * 添加组item
     */
    addGroupItem(){
      this.PKGame.group.push({
        name:"",
        type:null,
        lowTarget:null,
        tallTarget:null,
        picture:null,
        groupMember:[],
        groupMemberList:[]
      })
    },
    /**
     * 选择校区类型的时候重新去请求校区数据
     */
    changeCampusType(val){
      this.getAreaList(val);
    },
    /**
     * 查询教练列表
     */
    getCoach(){
      this.fatherTree.coachLoading = true;
      let query = {
        "areaList":[], "pageSize":20,
        "keyword":this.fatherTree.coachName,
      };
      pageList(query).then(val => {
        let list = [];
        this.fatherTree.coachLoading = false;
        if (val.code == 200) {
          val.data.records.forEach(function(item){
            list.push({'id':item.coachId,"name":item.coachName})
          })
        }
        this.fatherTree.coachTree = list;
      })
    },
    /**
     * 获取校区树结构列表
     * @param typeId
     */
    getAreaList(typeId){
      this.fatherTree.campusLoading = true;
      listAreaTreeAddCoach(typeId).then(val => {
        this.fatherTree.campusLoading = false;
        if (val.code==200){
          this.fatherTree.campusTree = this.disposeTree(val.data);
        }
      })
    },
    /**
     * 查询城市下的账户列表
     */
    selectRegionAccount(){
      this.fatherTree.regionLoading = true;
      selectRegionAccount(this.fatherTree.regionName).then(val => {
        this.fatherTree.regionLoading = false;
        if (val.code == 200 && val.data.length>0){
          this.fatherTree.regionTree = val.data;
        }
      })
    },
    /**
     * 树结构的children会存在[]、null 统一处理为 undefined
     */
    disposeTree(tree) {
      if (tree!=null){
        tree.forEach(items => {
          if (items!=null){
            if (items.memberId!=null){
              items.id = items.memberId;
              items.name = items.memberName;
            }
            if (items.children != null && items.children.length > 0) {
              this.disposeTree(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return tree;
      }
    },
    dateFormat(val) {
      var date = new Date(val);
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
    },
    /**
     * 组数据验证
     */
    verification(item,index){
      console.log(item)
      if (item.name == null || item.name == ""){
        this.$message.error("请输入第 "+index+" 组的组名！");
        return false;
      }
      if (item.lowTarget == null || item.lowTarget < 0){
        this.$message.error("请输入"+item.name+"组的基础目标！");
        return false;
      }
      if (item.tallTarget == null || item.tallTarget < 0){
        this.$message.error("请输入"+item.name+"组：冲刺目标！");
        return false;
      }
      if (item.groupMember == null || item.groupMember.length<1 || item.type == null){
        this.$message.error("请拖拽左侧树结构到 "+item.name+"组 里面！");
        return false;
      }
      return true;
    },

    /**
     * 处理比赛各组的成员树结构的pid
     */
    disposeGroupTree(tree,fatherId,groupIndex){
      let group = this.PKGame.group[groupIndex];
      tree.forEach(item => {
        item.pid = fatherId;
        let member = {
          "memberId":item.id,
          "memberName":item.name,
          "memberType":group.type,
          "fatherId":item.pid,
          "isTreeBottom":item.children == null ? 1:0
        }
        group.groupMemberList.push(member)
        if (item.children != null && item.children.length > 0) {
          this.disposeGroupTree(item.children,item.id,groupIndex);
        }
      });
    },

    updatePKGame(){
      updatePKGame(this.PKGame).then(val => {
        this.updateDialog = false;
        if(val.code==200){
          this.$emit("selectTable");
          this.$message.success('更新成功！');
        }else{
          this.$message.error('更新失败！');
        }
      })
    }
  },
  created() {
    this.getCoach();
    this.getAreaList();
    this.selectRegionAccount();
    listXcxDept().then(res => {
      this.fatherTree.deptTree = this.disposeTree(res.data);
    })
  },
}
</script>

<style scoped>
.picture{
  width: 70px;
  height: 70px;
  border: #dcdfe6 1px solid;
  border-radius: 10px;
}
.remove-picture{
  position: absolute;
  top: 4px;
  left: 55px;
  font-size: 17px;
  font-weight: bold;
  color: red;
}
.left-item{
  width: 340px;
  padding: 20px;
  height: 450px;
  overflow: auto;
  border-radius: 20px;
  box-shadow: 0px 1px 18px 1px #f5f5f5;
}
::v-deep.hide .el-upload--picture-card {
  display: none;
}
.right-item{
  width: 100%;
  height: 450px;
  margin-left: 30px;
  padding: 10px 0;
  overflow: auto;
}
.group-item{
  width: 256px;
  padding: 20px;
  height: 360px;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
  border-radius: 20px;
  margin-bottom: 30px;
}
.el-tree-father{
  height: 260px;
  overflow: auto;
  height: 260px;
  margin-top: 10px;
}
.placeholder{
  width: 256px;
  padding: 20px;
  margin-bottom: 30px;
}
.inputDeep .el-input--medium .el-input__inner{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}
</style>
<style lang="scss">
.game-father{
  width: 98%;
  margin-top: -16px;
  margin-bottom: 28px;
  //background-color: #f5f7fa;
  box-shadow: 0px 1px 20px 1px #e8e8e8;
  padding: 20px 0 10px 0;
  border-radius: 10px;

}
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
.tree-item{
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 240px;
  //overflow: auto;
  padding-right: 10px
}

.input{
  border:none;border-bottom: 1px solid #dbdbdb;
  padding: 2px 0;
  margin: 2px 0;
  color: #909399;
}

</style>

<style scoped>

/deep/ .el-upload--picture-card{
  width: 70px;
  height: 70px;
}
/deep/ .el-upload{
  width: 70px;
  height: 70px;
  line-height: 70px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 70px;
  height: 70px;
  line-height: 70px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 70px;
  height: 70px;
  line-height: 70px;
}
/deep/ .avatar{
  width: 70px;
  height: 70px;
}
</style>
